// plugins/gitlab-issues/src/api/GitlabIssuesApi.ts

import {
  createApiRef,
  ConfigApi,
} from '@backstage/core-plugin-api';
import { Gitlab } from '@gitbeaker/browser';
import { ScmIntegrations } from '@backstage/integration';

export const gitlabIssuesApiRef = createApiRef<GitlabIssuesApi>({
  id: 'plugin.gitlab-issues.service',
});

export interface GitlabIssuesApi {
  getUserIssues(username: string): Promise<any[]>;
}

export class GitlabIssuesClient implements GitlabIssuesApi {
  private readonly gitlabClient: any;

  constructor(private readonly configApi: ConfigApi) {
    const integrations = ScmIntegrations.fromConfig(this.configApi);
    const gitlabIntegrations = integrations.gitlab.list();

    if (gitlabIntegrations.length === 0) {
      throw new Error('No GitLab integration configuration found');
    }

    // Assuming you want to use the first GitLab integration config
    const gitlabIntegration = gitlabIntegrations[0];

    const host = gitlabIntegration.config.baseUrl || `https://${gitlabIntegration.config.host}`;
    const token = gitlabIntegration.config.token || '';

    this.gitlabClient = new Gitlab({
      host,
      token,
    });
  }

  async getUserIssues(username: string): Promise<any[]> {
    // Fetch issues assigned to the given username
    const issues = await this.gitlabClient.Issues.all({
      assignee_username: username,
      state: 'opened',
    });

    return issues;
  }
}
