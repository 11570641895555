import React from 'react';

export const OperationalTasks = () => {
  return (
    <div>
      <h3>Operational Tasks</h3>
      <ul>
        <li>Task 1: In Progress</li>
        <li>Task 2: Completed</li>
        <li>Task 3: Pending</li>
      </ul>
    </div>
  );
};
