import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { costInsightsApiRef, ExampleCostInsightsClient } from '@backstage-community/plugin-cost-insights';
import { tfStateApiRef, TfStateApiClient, ecsServicesApiRef, EcsServicesClient} from '@internal/backstage-plugin-costs';
import { awsCloudWatchApiRef, AwsCloudWatchClient } from '@internal/backstage-plugin-monitoring';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: tfStateApiRef,
    deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
    factory: ({ discoveryApi, identityApi }) =>
      new TfStateApiClient(discoveryApi, identityApi),
  }),
  createApiFactory({
    api: ecsServicesApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, identityApi }) =>
      new EcsServicesClient(discoveryApi, identityApi),
  }),
  createApiFactory({
    api: awsCloudWatchApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, identityApi }) =>
      new AwsCloudWatchClient(discoveryApi, identityApi),
  }),
  createApiFactory(costInsightsApiRef, new ExampleCostInsightsClient()),
  ScmAuth.createDefaultApiFactory(),
];
