/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { EntityHighlightsCard } from '@rsc-labs/backstage-highlights-plugin';
import { EntityCostInsightsContent } from '@backstage-community/plugin-cost-insights';
import { SkillExchangeUserEntitySkillsCard } from '@spotify/backstage-plugin-skill-exchange';

import React from 'react';
import { Button, Grid } from '@material-ui/core';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
  hasRelationWarnings,
  EntityRelationWarning,
  isResourceType,
} from '@backstage/plugin-catalog';
import {
  isGithubActionsAvailable,
  EntityGithubActionsContent,
} from '@backstage-community/plugin-github-actions';
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EmptyState, Link } from '@backstage/core-components';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  Entity,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import {
  AwsAppPage,
  EntityAppStateCard,
  EntityAppStateCardCloudFormation,
  EntityGeneralInfoCard,
  EntityCloudwatchLogsTable,
  EntityInfrastructureInfoCard,
  EntityAppConfigCard,
  EntityAuditTable,
  AwsEnvironmentPage,
  AwsEnvironmentProviderPage,
  AwsComponentPage,
} from '@aws/plugin-aws-apps-for-backstage';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import {EntityAmazonEcsServicesContent} from '@aws/amazon-ecs-plugin-for-backstage';
import { isGitlabAvailable, EntityGitlabContent } from '@immobiliarelabs/backstage-plugin-gitlab';
import { EntityGitlabIssuesTable, EntityGitlabPeopleCard } from '@immobiliarelabs/backstage-plugin-gitlab';
import {
  isPluginApplicableToEntity as isPagerDutyAvailable,
  EntityPagerDutyCard,
} from '@pagerduty/backstage-plugin';
import { EntityGrafanaAlertsCard, EntityGrafanaDashboardsCard } from '@backstage-community/plugin-grafana';

const isCicdApplicable = (entity: Entity) => {
  return isGitlabAvailable(entity) || isGithubActionsAvailable(entity);
};
export const isServerlessRestApi = (entity: Entity): boolean => {
  const subType = entity?.metadata?.annotations?.['aws.amazon.com/opa-component-subtype'];
  return 'serverless-rest-api' === subType;
};
export const isLogsAvailable = (entity: Entity): boolean => {
  return !!entity?.metadata?.annotations?.['aws.amazon.com/opa-task-log-group'] ||
  'serverless-rest-api' === entity?.metadata?.annotations?.['aws.amazon.com/opa-component-subtype'];
};
import { EntityJiraDashboardContent, isJiraDashboardAvailable } from '@axis-backstage/plugin-jira-dashboard';
import useAsync from 'react-use/esm/useAsync';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { Container, Typography, List, ListItem, ListItemText, Link as MuiLink } from '@mui/material';

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
    </TechDocsAddons>
  </EntityTechdocsContent>
);
const CreateResourcePage: React.FC = () => {
  const catalogApi = useApi(catalogApiRef);
  const { entity } = useEntity(); // Fetch current entity details

  if (!entity) {
    return <div>Loading entity details...</div>;
  }

  const componentName = entity.metadata.name;

  // Fetch templates with the component name as a tag
  const { value: templates, loading, error } = useAsync(async () => {
    try {
      const response = await catalogApi.getEntities({
        filter: [
          { kind: 'template' }, // Filter for templates
          { 'metadata.tags': componentName }, // Filter for templates with the component name as a tag
        ],
        fields: ['metadata', 'spec'], // Fetch relevant metadata and spec fields
      });

      return response.items;
    } catch (err) {
      console.error('Error fetching templates:', err);
      return [];
    }
  }, [componentName]);

  if (loading) {
    return <div>Loading templates...</div>;
  }

  if (error || !templates) {
    return <div>Error loading templates</div>;
  }

  if (templates.length === 0) {
    return <div>No templates available for this component</div>;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Available templates
      </Typography>
      <List>
        {templates.map(template => (
          <ListItem key={template.metadata.name}>
            <ListItemText
              primary={
                <MuiLink component={Link} to={`/create/templates/default/${template.metadata.name}`}>
                  {template.metadata.name}
                </MuiLink>
              }
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};
const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <EntitySwitch>
  <EntitySwitch.Case if={isGithubActionsAvailable}>
    <EntityGithubActionsContent />
  </EntitySwitch.Case>
  <EntitySwitch.Case if={isGitlabAvailable}>
    <EntityGitlabContent />
  </EntitySwitch.Case>
  
  <EntitySwitch.Case>
    <EmptyState
      title="No CI/CD available for this entity"
      missing="info"
      description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
      action={
        <Button
          variant="contained"
          color="primary"
          href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
        >
          Read more
        </Button>
      }
    />
  </EntitySwitch.Case>
</EntitySwitch>
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    
    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={12} xs={12}>
      <EntityHighlightsCard />
    </Grid>
    <Grid item md={4} xs={8}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={isPagerDutyAvailable}>
        <Grid item md={6}>
          <EntityPagerDutyCard />
        </Grid>
      </EntitySwitch.Case>
      <EntitySwitch.Case if={isGitlabAvailable}>
        <Grid item md={8} xs={8}>
            <EntityGitlabIssuesTable />
        </Grid>
        <Grid item md={4} xs={8}>
            <EntityGitlabPeopleCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <Grid item md={4} xs={12}>
      <EntityLinksCard />
    </Grid>
  </Grid>
);

const serviceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD" >
      {cicdContent}
    </EntityLayout.Route>
    <EntityLayout.Route
    if={isJiraDashboardAvailable}
    path="/jira-dashboard"
    title="Tasks Dashboard"
    >
      <EntityJiraDashboardContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/create" title="Create components" >
      <CreateResourcePage />
    </EntityLayout.Route>
    {/* <EntityLayout.Route path="/costs" title="Cost Insights">
      <EntityCostInsightsContent />
    </EntityLayout.Route>
    < EntityLayout.Route path = "/ecs" title = "Amazon ECS" >
      <EntityAmazonEcsServicesContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/monitoring" title="Monitoring">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityGrafanaDashboardsCard />
        </Grid>
        <Grid item md={6}>
          <EntityGrafanaAlertsCard />
        </Grid>
      </Grid>
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const websiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/monitoring" title="Monitoring">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          {/* Grafana alert card start */}
          <EntityGrafanaDashboardsCard />
          {/* Grafana alert card end */}
        </Grid>
        <Grid item md={6}>
          {/* Grafana alert card start */}
          <EntityGrafanaAlertsCard />
          {/* Grafana alert card end */}
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */
const awsEcsAppViewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} showArrowHeads />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityLinksCard />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityGeneralInfoCard />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityAppStateCard></EntityAppStateCard>
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityAppConfigCard></EntityAppConfigCard>
    </Grid>
    <Grid item md={12} xs={12}>
      <EntityInfrastructureInfoCard />
    </Grid>
  </Grid>
  );
  const awsServerlessRestApiAppViewContent = (
    <Grid container spacing={3} alignItems="stretch">
      {entityWarningContent}
      <Grid item md={6}>
        <EntityAboutCard variant="gridItem" />
      </Grid>
      <Grid item md={6} xs={12}>
        <EntityCatalogGraphCard variant="gridItem" height={400} showArrowHeads />
      </Grid>
      <Grid item md={6} xs={12}>
        <EntityLinksCard />
      </Grid>
      <Grid item md={6} xs={12}>
        <EntityGeneralInfoCard />
      </Grid>
      <Grid item md={12} xs={12}>
        <EntityAppStateCardCloudFormation />
      </Grid>
      <Grid item md={12} xs={12}>
        <EntityInfrastructureInfoCard />
      </Grid>
    </Grid>
    );
    
    const awsAppViewContent = (
    <EntitySwitch>
      <EntitySwitch.Case if={isServerlessRestApi} children={awsServerlessRestApiAppViewContent} />
      <EntitySwitch.Case>{awsEcsAppViewContent}</EntitySwitch.Case>
    </EntitySwitch>
    );
    
    const awsAppLogsContent = (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item md={12} xs={12}>
        <EntityCloudwatchLogsTable />
      </Grid>
    </Grid>
    );
    const auditContent = (
    <Grid container spacing={1} alignItems="stretch">
      {entityWarningContent}
      <Grid item md={12} xs={12}>
        <EntityAuditTable />
      </Grid>
    </Grid>
    );
    
    // add awsEntityPage object
    const awsAppEntityPage = (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        {awsAppViewContent}
      </EntityLayout.Route>
      <EntityLayout.Route path="/ci-cd" title="CI/CD">
        {cicdContent}
      </EntityLayout.Route>
      <EntityLayout.Route path="/logs" title="App Logs" if={isLogsAvailable}>
        {awsAppLogsContent}
      </EntityLayout.Route>
      <EntityLayout.Route path="/audit" title="Audit">
        {auditContent}
      </EntityLayout.Route>
    </EntityLayout>
    );
const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD" if={isCicdApplicable}>
      {cicdContent}
    </EntityLayout.Route>
    <EntityLayout.Route
    if={isJiraDashboardAvailable}
    path="/jira-dashboard"
    title="Jira Dashboard"
  >
    <EntityJiraDashboardContent />
  </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const componentPage = (

  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>
  {/* AWS Modifications */ }

    <EntitySwitch.Case if={isComponentType('aws-app')}>
      <AwsComponentPage componentType='aws-app'/>
      <AwsAppPage>
        {awsAppEntityPage}
      </AwsAppPage>
    </EntitySwitch.Case>
    {/* End of AWS Modifications */ }

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);


const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <SkillExchangeUserEntitySkillsCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <EntityMembersListCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
{/* AWS Modifications */ }

const awsEnvironmentProviderEntityPage = (
  <AwsEnvironmentProviderPage />
);

const awsEnvironmentEntityPage = (
  <AwsEnvironmentPage />
);

const resourceEntityPage = (
<EntitySwitch>
<EntitySwitch.Case if={isResourceType('aws-resource')}>
   <AwsComponentPage componentType='aws-resource'/>
</EntitySwitch.Case>
<EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
</EntitySwitch>
);

{/* End of AWS Modifications */ }
export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    {/* AWS Modifications */ }
    <EntitySwitch.Case if={isKind('resource')} children={resourceEntityPage} />
    <EntitySwitch.Case if={isKind('awsenvironment')} children={awsEnvironmentEntityPage} />
    <EntitySwitch.Case if={isKind('awsenvironmentprovider')} children={awsEnvironmentProviderEntityPage} />
    {/* End AWS Modifications */ }
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);