import React from 'react';

export const CostOverview = () => {
  return (
    <div>
      <h3>Cost Overview</h3>
      <ul>
        <li>Total Monthly Cost: $5000</li>
        <li>Service A: $2000</li>
        <li>Service B: $1500</li>
        <li>Service C: $1500</li>
      </ul>
    </div>
  );
};
