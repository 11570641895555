// plugins/aws-ecs/src/api/EcsServicesApi.ts

import { createApiRef, DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';

export const ecsServicesApiRef = createApiRef<EcsServicesApi>({
  id: 'plugin.aws-ecs.service',
});

export interface EcsServicesApi {
  getEcsServices(): Promise<any[]>;
  searchEcsServices(query: string): Promise<any[]>;
}

export class EcsServicesClient implements EcsServicesApi {
  constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly identityApi: IdentityApi,
  ) {}

  async getEcsServices(): Promise<any[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('aws-ecs-clusters');
    const url = `${baseUrl}/ecs-services`;

    // Get the user's token
    const { token } = await this.identityApi.getCredentials();

    // Include the Authorization header
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch ECS services, status ${response.status}`);
    }

    return await response.json();
  }

  async searchEcsServices(query: string): Promise<any[]> {
    // Utilize Backstage's search API
    const searchUrl = await this.discoveryApi.getBaseUrl('search');

    // Get the user's token
    const { token } = await this.identityApi.getCredentials();

    const response = await fetch(`${searchUrl}/query`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        term: query,
        filters: {
          type: 'ecsService',
        },
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to search ECS services, status ${response.status}`);
    }

    const searchResults = await response.json();

    // Map the search results to your data structure
    return searchResults.results
      .filter((result: any) => result.document.type === 'ecsService')
      .map((result: any) => result.document.fields);
  }
}
