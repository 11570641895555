import {
  createPlugin,
  createRoutableExtension,
  createApiFactory,
  configApiRef,
} from '@backstage/core-plugin-api';
import { gitlabIssuesApiRef, GitlabIssuesClient } from './components/GitlabIssuesApi';
import { rootRouteRef } from './routes';

export const taskpagePlugin = createPlugin({
  id: 'taskpage',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: gitlabIssuesApiRef,
      deps: {
        configApi: configApiRef,
      },
      factory: ({ configApi }) => new GitlabIssuesClient(configApi),
    }),
  ],
});

export const TaskpagePage = taskpagePlugin.provide(
  createRoutableExtension({
    name: 'TaskpagePage',
    component: () =>
      import('./components/TaskPage').then(m => m.TaskPage),
    mountPoint: rootRouteRef,
  }),
);
