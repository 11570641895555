import React from 'react';

export const Alerts = () => {
  return (
    <div>
      <h3>Alerts</h3>
      <ul>
        <li>Alert 1: Critical - Service A</li>
        <li>Alert 2: Warning - Service B</li>
        <li>Alert 3: Info - Service C</li>
      </ul>
    </div>
  );
};
