import React from 'react';

export const ServiceOverview = () => {
  return (
    <div>
      <h3>Service Overview</h3>
      <ul>
        <li>Service A: Running</li>
        <li>Service B: Maintenance</li>
        <li>Service C: Down</li>
      </ul>
    </div>
  );
};
