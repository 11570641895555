import React from 'react';

export const KeyMetricsAndIncidents = () => {
  return (
    <div>
      <h3>Key Metrics and Incidents</h3>
      <ul>
        <li>Availability: 99.9%</li>
        <li>Incidents: 2 in the last 24 hours</li>
        <li>Response Time: Average 200ms</li>
      </ul>
    </div>
  );
};
